$().ready(function() {

    $(".service ul li:last-child").css('margin-right', '0px')
    $(".top_top_right span:eq(1)").css('margin-left', '30px')
    $(".top_bot_right p a:eq(0) img:eq(1)").css('display', 'none')
    $(".top_bot_right p a:eq(1) img:eq(1)").css('display', 'none')
    $(".top_bot_right p a:eq(2) img:eq(1)").css('display', 'none')
    $(".footer_left p a:eq(0) img:eq(1)").css('display', 'none')
    $(".footer_left p a:eq(1) img:eq(1)").css('display', 'none')
    $(".footer_left p a:eq(2) img:eq(1)").css('display', 'none')
    $(".search ul li:eq(0)").css('')
    $(".search ul li:eq(1)").css('display', 'none')
    $(".service ul li:last-child").css('margin-right', '0px')
    $(".prodisplay ul li:eq(3)").css('margin-right', '0px')
    $(".prodisplay ul li:eq(7)").css('margin-right', '0px')
    $(".cgal ul li:eq(3)").css('margin-right', '0px')
    $(".cgal ul li:eq(7)").css('margin-right', '0px')
    $(".cpzx .ul2 li:eq(3)").css('margin-right', '0px')
    $(".cpzx .ul2 li:eq(7)").css('margin-right', '0px')
    $(".gywm_ad_c ul li:eq(1)").css('margin-right', '0px')
    $(".gywm_ad_c ul li:eq(3)").css('margin-right', '0px')
    $(".zxyy_right input:eq(3)").css('height', '72px')
    $(".lxwm_left p:eq(0) img").css('width', '60px').css('height', '60px')
    $(".lxwm_left p:eq(1) img").css('width', '60px').css('height', '60px')
    $(".lxwm_left p:eq(2) img").css('width', '43px').css('height', '43px').css('margin-left', '10px').css('margin-right', '8px')
    $(".lxwm_left p:eq(2)").css('border', 'none')
    $(".content span:eq(0)").css('padding-top', '30px')
    $(".content span:eq(1)").css('padding-bottom', '30px')

    $(".top_bot_right p a:eq(0)").mouseover(function() {
        $(".top_bot_right p a:eq(0) img:eq(0)").css('display', 'none');
        $(".top_bot_right p a:eq(0) img:eq(1)").css('display', 'inline');
    });
    $(".top_bot_right p a:eq(1)").mouseover(function() {
        $(".top_bot_right p a:eq(1) img:eq(0)").css('display', 'none');
        $(".top_bot_right p a:eq(1) img:eq(1)").css('display', 'inline');
    });
    $(".top_bot_right p a:eq(2)").mouseover(function() {
        $(".top_bot_right p a:eq(2) img:eq(0)").css('display', 'none');
        $(".top_bot_right p a:eq(2) img:eq(1)").css('display', 'inline');
    });
    $(".top_bot_right p a:eq(0)").mouseleave(function() {
        $(".top_bot_right p a:eq(0) img:eq(1)").css('display', 'none');
        $(".top_bot_right p a:eq(0) img:eq(0)").css('display', 'inline');
    });
    $(".top_bot_right p a:eq(1)").mouseleave(function() {
        $(".top_bot_right p a:eq(1) img:eq(1)").css('display', 'none');
        $(".top_bot_right p a:eq(1) img:eq(0)").css('display', 'inline');
    });
    $(".top_bot_right p a:eq(2)").mouseleave(function() {
        $(".top_bot_right p a:eq(2) img:eq(1)").css('display', 'none');
        $(".top_bot_right p a:eq(2) img:eq(0)").css('display', 'inline');
    });
    $(".footer_left .p3 a:eq(0)").mouseover(function() {
        $(".footer_left .p3 a:eq(0) img:eq(0)").css('display', 'none');
        $(".footer_left .p3 a:eq(0) img:eq(1)").css('display', 'inline');
    });
    $(".footer_left .p3 a:eq(1)").mouseover(function() {
        $(".footer_left .p3 a:eq(1) img:eq(0)").css('display', 'none');
        $(".footer_left .p3 a:eq(1) img:eq(1)").css('display', 'inline');
    });
    $(".footer_left .p3 a:eq(2)").mouseover(function() {
        $(".footer_left .p3 a:eq(2) img:eq(0)").css('display', 'none');
        $(".footer_left .p3 a:eq(2) img:eq(1)").css('display', 'inline');
    });
    $(".footer_left .p3 a:eq(0)").mouseleave(function() {
        $(".footer_left .p3 a:eq(0) img:eq(1)").css('display', 'none');
        $(".footer_left .p3 a:eq(0) img:eq(0)").css('display', 'inline');
    });
    $(".footer_left .p3 a:eq(1)").mouseleave(function() {
        $(".footer_left .p3 a:eq(1) img:eq(1)").css('display', 'none');
        $(".footer_left .p3 a:eq(1) img:eq(0)").css('display', 'inline');
    });
    $(".footer_left .p3 a:eq(2)").mouseleave(function() {
        $(".footer_left .p3 a:eq(2) img:eq(1)").css('display', 'none');
        $(".footer_left .p3 a:eq(2) img:eq(0)").css('display', 'inline');
    });
    $(".service ul li:eq(2) .p1,.service ul li:eq(3) .p1").css('left','500px')
    $(".service ul li:eq(2) .p2,.service ul li:eq(3) .p2").css('left','500px')
    $(".aboutus_right a").css('display','none').css('transform','scale(0.5)')
    $(".prodisplay .more").css('display','none').css('transform','scale(0.5)')
    $(".info .more").css('display','none').css('transform','scale(0.5)')
    $(".zxyy_left a").css('display','none').css('transform','scale(0.5)')

});

$(window).scroll(function() {
    var h = $(this).scrollTop(); //获得滚动条距top的高度
    //alert(h); 
    if (h > 135) {
        $(".box_nav").css('position', 'fixed').css('top', '0px');
        $(".search").fadeOut();
    } else {
        $(".box_nav").css('position', 'relative')
        $(".search").fadeIn();
    }
    if (h > 150) {
        $(".service ul li img").css('transform','rotateY(360deg)');
    }
    if (h > 170) {
        $(".service ul li:eq(0) .p1,.service ul li:eq(1) .p1").fadeIn(200).animate({left:'0'},700);
    }
    if (h > 170) {
        $(".service ul li:eq(0) .p2,.service ul li:eq(1) .p2").fadeIn(200).animate({left:'0'},700);
    }
    if (h > 170) {
        $(".service ul li:eq(2) .p1,.service ul li:eq(3) .p1").fadeIn(200).animate({left:'0'},700);
    }
    if (h > 170) {
        $(".service ul li:eq(2) .p2,.service ul li:eq(3) .p2").fadeIn(200).animate({left:'0'},700);
    }
    if (h > 700) {
        $(".aboutus_left p").fadeIn(200).animate({left:'0'},700);
    }
    if (h > 700) {
        $(".aboutus_right img").fadeIn(200).animate({left:'0'},700);
    }
    if (h > 800) {
        $(".aboutus_right a").fadeIn(200).css('transform','scale(1)');
    }
    if (h > 1800) {
        $(".prodisplay .more").fadeIn(200).css('transform','scale(1)');
    }
    if (h > 2800) {
        $(".info .more").fadeIn(200).css('transform','scale(1)');
    }
    if (h > 90) {
        $(".gywm_content p").animate({opacity:'1'},100).animate({left:'0'},700);
    }
    if (h > 650) {
        $(".gywm_ad_c ul li").animate({opacity:'1'},100).animate({left:'0'},900);
    }
    if (h > 250) {
        $(".zxyy_left a").fadeIn(200).css('transform','scale(1)');
    }
});

$(function() {
    new Slider($("#banner_tabs"), {
        time: 5e3,
        delay: 400,
        event: "hover",
        auto: !0,
        mode: "fade",
        controller: $("#bannerCtrl"),
        activeControllerCls: "active"
    })
});
